@use '~nbds-react/lib/styles/breakpoints' as *;
@use '~nbds-react/lib/styles/styles.scss';

// Theme

:root {
  --nbds-neutral-dark: #767676; // Todo: Remove after updating NBDS to 1.3.1 or later.
}

// Globals

body, html {
  background-color: #fff;
  height: 100%;
}

a *, button * {
  pointer-events: none; // Needed for GTM / GA4 Triggers
}

// Overrides

.nbds-listbox li {
  white-space: unset;
}

@media (max-width: $nbds-bp-sm-max) {

  .nbds-modal-dialog {

    &:focus {
      box-shadow: none; // Todo: Remove after updating NBDS to 1.3.1 or later.
    }
  }

}
