@use '~nbds-react/lib/styles/breakpoints' as *;

div#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.app-main {
  flex-grow: 1;
}
