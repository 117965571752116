@use '~nbds-react/lib/styles/breakpoints' as *;

@media print {

  .app-header {
    display: none;
  }

}

.app-header {
  background: white;
  padding: 0 2rem;
}

.app-header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90rem;
  height: 4rem;
  margin: 0 auto;

  img {
    display: block;
    width: 10rem;
  }

  p {
    font-family: var(--nbds-secondary-font-face);
    margin: 0 0 0 auto;
  }
}

@media (min-width: $nbds-bp-md) {

  .app-header-content {
    height: 5rem;

    img {
      width: 12rem;
    }
  }

}
