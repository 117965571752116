@use '~nbds-react/lib/styles/breakpoints' as *;

@media print {

  .nbds-footer {
    display: none;
  }

}

div.nbds-footer-slot-6 {
  overflow: visible;
}

.nbds-footer-legal {
  text-align: center;

  p {
    font-size: var(--nbds-font-size-02);
    margin: 0.875rem 0 -0.0625rem;
  }

  img {
    max-width: 10rem;
  }
}

.nbrx-copyright {
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    margin-left: 1rem;
  }
}

@media (min-width: $nbds-bp-md) {

  .nbds-footer-legal {
    display: flex;
    justify-content: space-between;
    text-align: right;

    p {
      margin: 0 0 0 1rem;
    }
  }

}